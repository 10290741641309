import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  background-color: #000033;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  color: #fff;

  @media (max-width: 576px) {
    padding: 10px;
  }

  @media only screen and (max-width: 425px) {
    padding: 8px;
  }

  @media only screen and (max-width: 375px) {
    padding: 5px;
  }
`;

const ScrollableTableContainer = styled.div`
  width: 100%;
  overflow-x: auto; /* Horizontal scroll for smaller screens */
  margin-top: 20px;

  @media (max-width: 576px) {
    margin-top: 15px;
  }

  @media (max-width: 425px) {
    margin-top: 10px;
  }

  @media (max-width: 375px) {
    margin-top: 8px;
  }
`;

const Table = styled.table`
  width: 100%;
  min-width: 600px; /* Minimum width for better scrolling */
  border-collapse: collapse;
  font-size: 1rem;
  margin-top: 20px;

  th, td {
    border: 1px solid #fff;
    padding: 12px;
    text-align: left;
  }

  th {
    background-color: #f0e00f;
    color: #000;
  }

  @media (max-width: 992px) {
    font-size: 0.9rem;
  }

  @media (max-width: 576px) {
    font-size: 0.8rem;
    padding: 8px;
  }

  @media only screen and (max-width: 425px) {
    font-size: 0.75rem;
    padding: 6px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 0.7rem;
    padding: 5px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  flex-wrap: wrap;

  h1 {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;

    h1 {
      font-size: 1.3rem;
    }
  }
`;

const Button = styled.button`
  font-size: 0.9rem;
  padding: 6px 12px;
  margin: 5px;
  background-color: ${props => (props.variant === 'complete' ? '#28a745' : '#f0e00f')};
  color: #000033;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${props => (props.variant === 'complete' ? '#218838' : '#e0d00f')};
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 5px 10px;
  }

  @media (max-width: 576px) {
    font-size: 0.75rem;
    padding: 4px 8px;
  }
`;

const InfoSection = styled.div`
  margin: 20px 0;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }

    @media (max-width: 576px) {
      font-size: 1rem;
    }
  }

  p {
    margin: 5px 0;
    font-size: 1rem;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }

    @media (max-width: 576px) {
      font-size: 0.85rem;
    }
  }
`;

const NoPayoutMessage = styled.p`
  border: 2px solid #f0e00f;
  background-color: #000033;
  color: #fff;
  padding: 10px;
`;

const ManagePayout = () => {
  const [payouts, setPayouts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPayouts = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://server.digitalspayout.com/api/crypto/payouts');
      setPayouts(response.data.data.data || []); // Ensure payouts is an array
      setLoading(false);
    } catch (err) {
      setError('Error fetching payouts');
      setLoading(false);
    }
  };

  const approvePayout = async (payoutId) => {
    try {
      const response = await axios.put(`https://server.digitalspayout.com/api/payouts/${payoutId}/approve`);
      if (response.data.success) {
        Swal.fire('Success', response.data.message, 'success');
        fetchPayouts(); // Refresh list after approval
      }
    } catch (err) {
      Swal.fire('Error', 'Unable to approve payout', 'error');
    }
  };

  const rejectPayout = async (payoutId) => {
    try {
      const response = await axios.put(`https://server.digitalspayout.com/api/payouts/${payoutId}/reject`);
      if (response.data.success) {
        Swal.fire('Success', response.data.message, 'success');
        fetchPayouts(); // Refresh list after rejection
      }
    } catch (err) {
      Swal.fire('Error', 'Unable to reject payout', 'error');
    }
  };

  useEffect(() => {
    fetchPayouts();
  }, []);

  if (loading) {
    return <div className="loading">Loading payouts...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <Container>
      <Header>
        <h1>Manage Payouts</h1>
      </Header>
      {payouts.length === 0 ? (
        <NoPayoutMessage>No payouts available.</NoPayoutMessage>
      ) : (
        <ScrollableTableContainer>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Depositor Name</th>
                <th>Wallet Address</th>
                <th>Amount</th>
                <th>Currency</th>
                <th>Plan Name</th>
                <th>Subscription Type</th>
                <th>Subscription Period</th>
                <th>Requested Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(payouts) && payouts.map((payout, index) => (
                <tr key={payout.payoutId}>
                  <td>{index + 1}</td>
                  <td>{payout.depositorName}</td>
                  <td>{payout.walletAddress}</td>
                  <td>{payout.amount ? payout.amount.toLocaleString() : 'N/A'}</td>
                  <td>{` ${payout.currencyCode}(${payout.currencySymbol})`}</td>
                  <td>{payout.planName}</td>
                  <td>{payout.subscriptionType}</td>
                  <td>
                    {dayjs(payout.subscriptionStartDate).format('MMM DD, YYYY')} - {dayjs(payout.subscriptionEndDate).format('MMM DD, YYYY')}
                  </td>
                  <td>{dayjs(payout.payoutCreatedAt).format('MMM DD, YYYY')}</td>
                  <td>{payout.payoutStatus}</td>
                  <td>
                    {payout.payoutStatus === 'pending' ? (
                      <>
                        <Button variant="complete" onClick={() => approvePayout(payout.payoutId)}>Approve</Button>
                        <Button onClick={() => rejectPayout(payout.payoutId)}>Reject</Button>
                      </>
                    ) : (
                      <span>{payout.status ? payout.status.toUpperCase() : 'N/A'}</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollableTableContainer>
      )}
    </Container>
  );
};

export default ManagePayout;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Swal from 'sweetalert2';
// import dayjs from 'dayjs';
// import styled from 'styled-components';

// const Container = styled.div`
//   padding: 20px;
//   background-color: #f8f9fa;

//   h2 {
//     color: #dc3545;
//     text-align: center;
//     margin-bottom: 20px;
//   }

//   .loading {
//     text-align: center;
//     font-size: 20px;
//     color: #007bff;
//   }

//   .error {
//     color: #dc3545;
//     text-align: center;
//     margin-bottom: 20px;
//     font-weight: bold;
//   }

//   .table-responsive {
//     overflow-x: auto;
//   }

//   table {
//     width: 100%;
//     border-collapse: collapse;
//     margin-top: 20px;
//     border-radius: 8px;
//     overflow: hidden;

//     thead {
//       background-color: #343a40;
//       color: white;
//     }

//     th,
//     td {
//       border: 2px solid #f0e00f;
//       padding: 15px;
//       text-align: center;

//       &:first-child {
//         width: 50px;
//       }
//     }

//     tbody tr {
//       background-color: #000033;
//       color: white;

//       &:hover {
//         background-color: #007bff;
//         transition: background-color 0.3s ease;
//       }

//       &:nth-child(even) {
//         background-color: #1c1c1c; // Alternating row color
//       }
//     }
//   }

//   button {
//     margin-right: 5px;
//     padding: 8px 12px;
//     font-size: 14px;
//     border-radius: 4px;
//     cursor: pointer;

//     &.btn-success {
//       background-color: #28a745;
//       border: none;
//       color: white;

//       &:hover {
//         background-color: #218838;
//       }
//     }

//     &.btn-danger {
//       background-color: #dc3545;
//       border: none;
//       color: white;

//       &:hover {
//         background-color: #c82333;
//       }
//     }
//   }

//   @media (max-width: 768px) {
//     h2 {
//       font-size: 24px;
//     }

//     table {
//       font-size: 12px;
//     }

//     button {
//       font-size: 12px;
//     }
//   }

//   @media (max-width: 576px) {
//     h2 {
//       font-size: 20px;
//     }

//     table {
//       font-size: 10px;
//     }

//     button {
//       font-size: 10px;
//     }
//   }
// `;

// const ManagePayout = () => {
//   const [payouts, setPayouts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const fetchPayouts = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get('https://server.digitalspayout.com/api/crypto/payouts');
//       setPayouts(response.data.data.data || []); // Ensure payouts is an array
//       setLoading(false);
//     } catch (err) {
//       setError('Error fetching payouts');
//       setLoading(false);
//     }
//   };

//   const approvePayout = async (payoutId) => {
//     try {
//       const response = await axios.put(`https://server.digitalspayout.com/api/payouts/${payoutId}/approve`);
//       if (response.data.success) {
//         Swal.fire('Success', response.data.message, 'success');
//         fetchPayouts(); // Refresh list after approval
//       }
//     } catch (err) {
//       Swal.fire('Error', 'Unable to approve payout', 'error');
//     }
//   };

//   const rejectPayout = async (payoutId) => {
//     try {
//       const response = await axios.put(`https://server.digitalspayout.com/api/payouts/${payoutId}/reject`);
//       if (response.data.success) {
//         Swal.fire('Success', response.data.message, 'success');
//         fetchPayouts(); // Refresh list after rejection
//       }
//     } catch (err) {
//       Swal.fire('Error', 'Unable to reject payout', 'error');
//     }
//   };

//   useEffect(() => {
//     fetchPayouts();
//   }, []);

//   if (loading) {
//     return <div className="loading">Loading payouts...</div>;
//   }

//   if (error) {
//     return <div className="error">{error}</div>;
//   }

//   return (
//     <Container>
//       <h2>Manage Payouts</h2>
//       {payouts.length === 0 ? (
//         <p style={{ border: '2px solid #f0e00f', backgroundColor: '#000033', color: '#fff', padding: '10px' }}>
//           No payouts available.
//         </p>
//       ) : (
//         <div className="table-responsive">
//           <table>
//             <thead>
//               <tr>
//                 <th>#</th>
//                 <th>Payout ID</th>
//                 <th>Depositor Name</th>
//                 <th>Wallet Address</th>
//                 <th>Amount</th>
//                 <th>Currency</th>
//                 <th>Plan Name</th>
//                 <th>Subscription Type</th>
//                 <th>Subscription Period</th>
//                 <th>Requested Date</th>
//                 <th>Status</th>
//                 <th>Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {Array.isArray(payouts) && payouts.map((payout, index) => (
//                 <tr key={payout.payoutId}>
//                   <td>{index + 1}</td>
//                   {/* <td>{payout.payoutId}</td> */}
//                   <td>{payout.depositorName}</td>
//                   <td>{payout.walletAddress}</td>
//                   <td>{payout.amount ? payout.amount.toLocaleString() : 'N/A'}</td>
//                   <td>{` ${payout.currencyCode}(${payout.currencySymbol})`}</td>
//                   <td>{payout.planName}</td>
//                   <td>{payout.subscriptionType}</td>
//                   <td>
//                     {dayjs(payout.subscriptionStartDate).format('MMM DD, YYYY')} - {dayjs(payout.subscriptionEndDate).format('MMM DD, YYYY')}
//                   </td>
//                   <td>{dayjs(payout.payoutCreatedAt).format('MMM DD, YYYY')}</td>
//                   <td>{payout.payoutStatus}</td>
//                   <td>
//                     {payout.payoutStatus === 'pending' ? (
//                       <>
//                         <button
//                           className="btn btn-success btn-sm"
//                           onClick={() => approvePayout(payout.payoutId)}
//                         >
//                           Approve
//                         </button>
//                         <button
//                           className="btn btn-danger btn-sm"
//                           onClick={() => rejectPayout(payout.payoutId)}
//                         >
//                           Reject
//                         </button>
//                       </>
//                     ) : (
//                       <span>{payout.status ? payout.status.toUpperCase() : 'N/A'}</span>
//                     )}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       )}
//     </Container>
//   );
// };

// export default ManagePayout;


import React, { useState, useEffect } from 'react';
import { Table, Button, Alert, Spinner } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

const UnApprovedDepositsList = () => {
    const [deposits, setDeposits] = useState([]);
    const [allDeposits, setAllDeposits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [updating, setUpdating] = useState(null); // Track deposit being updated

    useEffect(() => {
        // Fetch deposits when component mounts
        fetchDeposits();
        fetchAllDeposits();
    }, []);

    const fetchDeposits = async () => {
        try {
            setLoading(true);
            const response = await axios.get('https://server.digitalspayout.com/api/crypto/all-pending-deposits');
            setDeposits(response.data || []); // Set deposits to response data
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch deposits.');
            setLoading(false);
        }
    };
    const fetchAllDeposits = async () => {
        try {
            setLoading(true);
            const response = await axios.get('https://server.digitalspayout.com/api/crypto/deposits');
            setAllDeposits(response.data.data.data || []); // Set deposits to response data
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch all deposits.');
            setLoading(false);
        }
    };

    const updateDepositStatus = async (depositId, status) => {
        try {
            setUpdating(depositId);
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: `You are about to ${status} the deposit.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: `Yes, ${status === 'accepted' ? 'accept' : 'reject'} it!`,
                cancelButtonText: 'No, cancel!',
            });

            if (result.isConfirmed) {
                const response = await axios.put(`https://server.digitalspayout.com/api/crypto/approve-deposit-status/${depositId}`, {
                    status,
                });

                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: `Deposit has been ${status === 'accepted' ? 'accepted' : 'rejected'}.`,
                        confirmButtonColor: '#000033',
                    });
                    fetchDeposits(); // Refresh deposits after update
                } else {
                    setError(response.data.message);
                }
            }
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to update deposit status.',
                confirmButtonColor: '#000033',
            });
        } finally {
            setUpdating(null);
        }
    };

    const tableStyle = {
        backgroundColor: '#000033', // Table background color
        color: '#fff', // Text color
    };

    const buttonStyle = (variant) => ({
        backgroundColor: variant === 'success' ? '#f0e00f' : '#000033', // Button color
        color: variant === 'success' ? '#000033' : '#f0e00f',
        border: `1px solid ${variant === 'success' ? '#f0e00f' : '#f0e00f'}`,
        padding: '0.375rem 0.75rem',
        fontSize: '0.9rem',
    });

    if (loading) {
        return <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>;
    }

    return (
        <>
      <div className="container align-items-center justify-content-center" style={{ height: '100%' }}>
      < div className="row align-items-center justify-content-center" style={{ height: '100%', backgroundColor:'#000033' }}>
        <div className="container-fluid">
            <h2 className="text-center my-4" style={{ color: '#f0e00f' }}>Unapproved Deposits Management</h2>

            {error && <Alert variant="danger text-danger">{error}</Alert>}

            <div className="table-responsive">
                <Table striped bordered hover responsive="sm" style={tableStyle} className="table-sm">
                    <thead style={{color:'#000033', border:'2px solid green', borderRadius:10,}}>
                        <tr style={{color:'#000033', border:'2px solid green', borderRadius:10,}}>
                            <th>#</th>
                            {/* <th>Wallet ID</th> */}
                            <th>Amount</th>
                            <th>Currency</th>
                            <th>Status</th>
                            <th>Depositor Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(deposits) && deposits.length > 0 ? (
                            deposits.map((deposit, index) => (
                                <tr key={deposit.id}>
                                    <td>{index + 1}</td>
                                    {/* <td>{deposit.walletId}</td> */}
                                    <td>{deposit.amount}</td>
                                    <td>{deposit.currencyName}</td> {/* Updated currency display */}
                                    <td>{deposit.status}</td>
                                    <td>{deposit.depositorName}</td> {/* Added depositor name */}
                                    <td>{deposit.phone}</td> {/* Added phone */}
                                    <td>{deposit.email}</td> {/* Added email */}
                                    <td>
                                        {deposit.status === 'pending' && (
                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    disabled={updating === deposit.id}
                                                    style={buttonStyle('success')}
                                                    onClick={() => updateDepositStatus(deposit.id, 'accepted')}
                                                >
                                                    {updating === deposit.id ? 'Processing...' : 'Accept'}
                                                </Button>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    disabled={updating === deposit.id}
                                                    style={buttonStyle('danger')}
                                                    onClick={() => updateDepositStatus(deposit.id, 'rejected')}
                                                >
                                                    {updating === deposit.id ? 'Processing...' : 'Reject'}
                                                </Button>
                                            </div>
                                        )}
                                        {deposit.status !== 'pending' && (
                                            <span>{deposit.status === 'accepted' ? 'Accepted' : 'Rejected'}</span>
                                        )}
                                    </td>

                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="9" className="text-center">No deposits found</td> {/* Updated colspan */}
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </div>
        </div>
      </div>

      <div className="container align-items-center justify-content-center" style={{ height: '100%' }}>
      < div className="row align-items-center justify-content-center" style={{ height: '100%', backgroundColor:'#000033' }}>
        <div className="container-fluid">
            <h2 className="text-center my-4" style={{ color: '#f0e00f' }}>All Deposit Management</h2>

            {error && <Alert variant="danger text-danger">{error}</Alert>}

            <div className="table-responsive">
                <Table striped bordered hover responsive="sm" style={tableStyle} className="table-sm">
                    <thead style={{color:'#000033', border:'2px solid green', borderRadius:10,}}>
                        <tr style={{color:'#000033', border:'2px solid green', borderRadius:10,}}>
                            <th>#</th>
                            {/* <th>Wallet ID</th> */}
                            <th>Amount</th>
                            <th>Currency</th>
                            <th>Status</th>
                            <th>Depositor Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(allDeposits) && allDeposits.length > 0 ? (
                            allDeposits.map((deposit, index) => (
                                <tr key={deposit.id}>
                                    <td>{index + 1}</td>
                                    {/* <td>{deposit.walletId}</td> */}
                                    <td>{deposit.amount}</td>
                                    <td>{deposit.currencyName}</td> {/* Updated currency display */}
                                    <td>{deposit.status}</td>
                                    <td>{deposit.depositorName}</td> {/* Added depositor name */}
                                    <td>{deposit.phone}</td> {/* Added phone */}
                                    <td>{deposit.email}</td> {/* Added email */}
                                    <td>
                                        {deposit.status === 'pending' && (
                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    disabled={updating === deposit.id}
                                                    style={buttonStyle('success')}
                                                    onClick={() => updateDepositStatus(deposit.id, 'accepted')}
                                                >
                                                    {updating === deposit.id ? 'Processing...' : 'Accept'}
                                                </Button>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    disabled={updating === deposit.id}
                                                    style={buttonStyle('danger')}
                                                    onClick={() => updateDepositStatus(deposit.id, 'rejected')}
                                                >
                                                    {updating === deposit.id ? 'Processing...' : 'Reject'}
                                                </Button>
                                            </div>
                                        )}
                                        {deposit.status !== 'pending' && (
                                            <span>{deposit.status === 'accepted' ? 'Accepted' : 'Rejected'}</span>
                                        )}
                                    </td>

                                    {/* <td>
                                        {deposit.status === 'pending' && (
                                            <div>
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    disabled={updating === deposit.id}
                                                    style={buttonStyle('success')}
                                                    onClick={() => updateDepositStatus(deposit.id, 'accepted')}
                                                >
                                                    {updating === deposit.id ? 'Processing...' : 'Accept'}
                                                </Button>{' '}
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    disabled={updating === deposit.id}
                                                    style={buttonStyle('danger')}
                                                    onClick={() => updateDepositStatus(deposit.id, 'rejected')}
                                                >
                                                    {updating === deposit.id ? 'Processing...' : 'Reject'}
                                                </Button>
                                            </div>
                                        )}
                                        {deposit.status !== 'pending' && (
                                            <span>{deposit.status === 'accepted' ? 'Accepted' : 'Rejected'}</span>
                                        )}
                                    </td> */}

                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="9" className="text-center">No deposits found</td> {/* Updated colspan */}
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </div>
        </div>
      </div>
      </>
    );
};

export default UnApprovedDepositsList;





// import React, { useState, useEffect } from 'react';
// import { Table, Button, Alert, Spinner } from 'react-bootstrap';
// import axios from 'axios';
// import Swal from 'sweetalert2';

// const UnApprovedDepositsList = () => {
//     const [deposits, setDeposits] = useState([]);
//     const [allDeposits, setAllDeposits] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [updating, setUpdating] = useState(null);

//     useEffect(() => {
//         fetchDeposits();
//         fetchAllDeposits();
//     }, []);

//     const fetchDeposits = async () => {
//         setLoading(true);
//         try {
//             const response = await axios.get('https://server.digitalspayout.com/api/crypto/all-pending-deposits');
//             setDeposits(response.data || []);
//         } catch {
//             setError('Failed to fetch pending deposits.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     const fetchAllDeposits = async () => {
//         setLoading(true);
//         try {
//             const response = await axios.get('https://server.digitalspayout.com/api/crypto/deposits');
//             console.log('====================================');
//             console.log('all deposits:' ,response.data.data.data || []);
//             console.log('====================================');
//             setAllDeposits(response.data.data.data || []);
//         } catch {
//             setError('Failed to fetch all deposits.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     const updateDepositStatus = async (depositId, status) => {
//         setUpdating(depositId);
//         const confirmText = status === 'accepted' ? 'accept' : 'reject';
//         const result = await Swal.fire({
//             title: 'Are you sure?',
//             text: `You are about to ${confirmText} the deposit.`,
//             icon: 'warning',
//             showCancelButton: true,
//             confirmButtonText: `Yes, ${confirmText} it!`,
//             cancelButtonText: 'No, cancel!',
//         });

//         if (result.isConfirmed) {
//             try {
//                 const response = await axios.put(`https://server.digitalspayout.com/api/crypto/approve-deposit-status/${depositId}`, { status });
//                 if (response.data.success) {
//                     Swal.fire({
//                         icon: 'success',
//                         title: 'Success!',
//                         text: `Deposit has been ${confirmText}ed.`,
//                     });
//                     fetchDeposits();
//                 } else {
//                     setError(response.data.message);
//                 }
//             } catch {
//                 Swal.fire({
//                     icon: 'error',
//                     title: 'Error!',
//                     text: 'Failed to update deposit status.',
//                 });
//             }
//         }
//         setUpdating(null);
//     };

//     const tableStyle = {
//         backgroundColor: '#000033',
//         color: '#fff',
//     };

//     const buttonStyle = (variant) => ({
//         backgroundColor: variant === 'success' ? '#f0e00f' : '#c82333',
//         color: variant === 'success' ? '#000033' : '#fff',
//         border: 'none',
//         padding: '0.375rem 0.75rem',
//         fontSize: '0.9rem',
//     });

//     if (loading) {
//         return <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>;
//     }

//     const renderTable = (deposits, allDeposits, title) => (
//         <div className="table-responsive">
//             <h2 className="text-center my-4" style={{ color: '#f0e00f' }}>{title}</h2>
//             <h2 className="text-center my-4" style={{ color: '#f0e00f' }}>{title}</h2>
//             {error && <Alert variant="danger">{error}</Alert>}
//             <Table striped bordered hover responsive="sm" style={tableStyle} className="table-sm">
//                 <thead>
//                     <tr>
//                         <th>#</th>
//                         <th>Amount</th>
//                         <th>Currency</th>
//                         <th>Status</th>
//                         <th>Depositor Name</th>
//                         <th>Phone</th>
//                         <th>Email</th>
//                         <th>Actions</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {Array.isArray(deposits) && deposits.length > 0 ? (
//                         deposits.map((deposit, index) => (
//                             <tr key={deposit.id}>
//                                 <td>{index + 1}</td>
//                                 <td>{deposit.amount}</td>
//                                 <td>{deposit.currencyName}</td>
//                                 <td>{deposit.status}</td>
//                                 <td>{deposit.depositorName}</td>
//                                 <td>{deposit.phone}</td>
//                                 <td>{deposit.email}</td>
//                                 <td>
//                                     {deposit.status === 'pending' ? (
//                                         <div style={{ display: 'flex', gap: '10px' }}>
//                                             <Button
//                                                 variant="success"
//                                                 size="sm"
//                                                 disabled={updating === deposit.id}
//                                                 style={buttonStyle('success')}
//                                                 onClick={() => updateDepositStatus(deposit.id, 'accepted')}
//                                             >
//                                                 {updating === deposit.id ? 'Processing...' : 'Accept'}
//                                             </Button>
//                                             <Button
//                                                 variant="danger"
//                                                 size="sm"
//                                                 disabled={updating === deposit.id}
//                                                 style={buttonStyle('danger')}
//                                                 onClick={() => updateDepositStatus(deposit.id, 'rejected')}
//                                             >
//                                                 {updating === deposit.id ? 'Processing...' : 'Reject'}
//                                             </Button>
//                                         </div>
//                                     ) : (
//                                         <span>{deposit.status === 'accepted' ? 'Accepted' : 'Rejected'}</span>
//                                     )}
//                                 </td>
//                             </tr>
//                         ))
//                     ) : (
//                         <tr>
//                             <td colSpan="8" className="text-center">No deposits found</td>
//                         </tr>
//                     )}
//                 </tbody>
//             </Table>
//         </div>
//     );

//     return (
//         <div className="container" style={{ backgroundColor: '#000033', padding: '20px' }}>
//             {renderTable(deposits, 'Unapproved Deposits Management')}
//             {renderTable(allDeposits, 'All Deposits Management')}
//         </div>
//     );
// };

// export default UnApprovedDepositsList;

import React from 'react'

function CrytocurrencyPrices() {
  return (
    <div>
       cry
    </div>
  )
}

export default CrytocurrencyPrices
